<template>
  <b-modal v-model="showModal" :title="$t('messages.sendVerificationCode') " content-class="  " header-class=" "
    centered cancel-variant="danger" hide-footer size="md" @hidden="fnResetModal()" footer-class="border-0">
    <div class="row">
      <div class="col-12 my-4">
        <h3 class="f-20 f-w-600 text-center" v-text="$t('messages.weWillResendCode')">
        </h3>
        <div>
          <p class="text-center">
            <b-button variant="link" v-text="$t('messages.changePhoneNumber')"
              @click="showPhoneInput = !showPhoneInput">
            </b-button>
          </p>
          <div v-show="showPhoneInput">
            <v-observer ref="formNewPhone" tag="form" class="" @submit.prevent="fnValidateNewPhone('Sms')">
              <v-validation rules="required|min:10|max:15" v-slot="{ validated,passed,errors }"
                :name="$t('general.form.phone')">
                <b-form-group :label="$t('general.form.phone')" label-for="input-formatter">
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text class=" bg-light">
                        <strong v-text=" '+' + userInfo.phoneCode"></strong>
                      </b-input-group-text>
                    </template>
                    <b-form-input v-model="newPhone" no-wheel type="number" :state=" (validated) ? passed : null "
                      id="newPhone" placeholder=" 80 8080 8080 " @input=" phoneError = false">
                    </b-form-input>
                  </b-input-group>
                  <b-form-invalid-feedback :state="(validated) ? passed : null" v-text="errors[0]">
                  </b-form-invalid-feedback>
                </b-form-group>
              </v-validation>
              <b-alert :show="phoneError" variant="danger">
                <p class="m-0" v-text="$t('messages.phoneAlreadyRegistered')"></p>
              </b-alert>
            </v-observer>
          </div>
        </div>
      </div>
      <div class="col-6">
        <b-button variant="info w-100" @click=" fnValidateNewPhone('Voice') " :disabled="loadingPhone">
          <div v-if="loadingPhone">
            <i class="fa fa-spinner fa-spin"></i>
          </div>
          <div v-else>
            <span v-text="$t('messages.sendByCall')"></span>
          </div>
        </b-button>
      </div>
      <div class="col-6">
        <b-button variant="success w-100" @click=" fnValidateNewPhone('Sms') " :disabled="loadingPhone">
          <div v-if="loadingPhone">
            <i class="fa fa-spinner fa-spin"></i>
          </div>
          <div v-else>
            <span v-text="$t('messages.sendSMS')"></span>
          </div>
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
  export default {
    props: ['userInfo'],
    data() {
      return {
        showModal: false,
        showPhoneInput: false,
        phoneError: false,
        loadingPhone: false,
        newPhone: null,
      }
    },
    methods: {
      fnResetModal() {
        this.showModal = false;
        this.showPhoneInput = false;
        this.phoneError = false;
        this.loadingPhone = false;
        this.phoneError = false;
        this.newPhone = null;
      },

      async fnValidateNewPhone(codeType) {
        if (this.showPhoneInput) {
          if (await this.$refs.formNewPhone.validate()) {
            this.fnValidateNewInfo(codeType);
          }
        } else {
          this.$emit('sendCode', codeType);
          this.fnResetModal();
        }
      },

      async fnValidateNewInfo(codeType) {
        this.loadingPhone = true;
        await axiosAccount.post("validateInfo", {
          first_name: this.userInfo.name,
          last_name: this.userInfo.lastName,
          email: this.userInfo.email,
          country: this.userInfo.country,
          phone: this.newPhone,
          password: this.userInfo.password,
          site_id: this.userInfo.siteId,
          to: this.userInfo.to || '/',
        }).then(async response => {
          this.$emit('changePhone', [codeType, this.newPhone]);
          this.fnResetModal();
        }).catch(error => {
          if (error.response.status == 403) {
            this.phoneError = true;
          }
        });
        setTimeout(() => {
          this.loadingPhone = false;
        }, 300);
      },
    }

  }
</script>

<style>

</style>